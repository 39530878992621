import React from 'react'; 
import { useTranslation } from 'react-i18next'; 

function DepositComponent({ item }) {
 
    const { t } = useTranslation();
  return (
        <div> 
            <div className="records_tabs"  key={item.order_number}>
              <div className="records_row">
                  <span className="records_row_title">{t('Transaction Time')}</span>
                  <span className="records_row_value">{item.datetime}</span> 
              </div>
              <div className="records_row">
                  <span className="records_row_title">{t('Order Number')}</span>
                  <span className="records_row_value">{item.order_number}</span>  
              </div>
  
              
              <div className="records_row">
                  <span className="records_row_title">{t('Amount(USDT)')}</span>
                  <span className="records_row_value">{item.amount}</span>  
              </div>
              <div className="records_row">
                  <span className="records_row_title">{t('Status')}</span>
                  <span className="records_row_value">{t(item.status)}</span>  
              </div> 
          </div>
        </div> 
  );
}

export default DepositComponent;
